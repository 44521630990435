(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/fraction-payment/assets/javascripts/use-trigger-payment.js') >= 0) return;  svs.modules.push('/components/marketplace/fraction-payment/assets/javascripts/use-trigger-payment.js');
"use strict";


const {
  useRef
} = React;
const {
  useHistory,
  useLocation
} = ReactRouterDOM;
const {
  QS_FRACTION_DATA
} = svs.components.marketplaceShared.sharedConstants.constants;
const useTriggerPayment = (activatedDrawId, groupId, isCurrentUserAuthenticated) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const marketplaceFractionData = searchParams.get(QS_FRACTION_DATA);
  const [queryActivatedDrawId, queryGroupId] = (marketplaceFractionData === null || marketplaceFractionData === void 0 ? void 0 : marketplaceFractionData.split('_')) || [];
  const ref = useRef(false);
  const clearPaymentTrigger = () => {
    ref.current = false;
    searchParams.delete(QS_FRACTION_DATA);
    if (history.location.state) {
      history.location.state.hasBackButton = true;
    } else {
      history.location.state = {
        hasBackButton: true
      };
    }
    history.replace({
      search: searchParams.toString()
    });
  };
  if (isCurrentUserAuthenticated && queryActivatedDrawId && queryGroupId) {
    if (parseInt(queryActivatedDrawId, 10) === activatedDrawId && parseInt(queryGroupId, 10) === groupId) {
      ref.current = true;
    }
  }
  return {
    triggerPayment: ref.current,
    clearPaymentTrigger
  };
};

setGlobal('svs.components.marketplace.fractionPayment.useTriggerPayment', useTriggerPayment);

 })(window);