(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/fraction-payment/assets/javascripts/use-cant-buy.js') >= 0) return;  svs.modules.push('/components/marketplace/fraction-payment/assets/javascripts/use-cant-buy.js');

'use strict';

const {
  useEffect,
  useState,
  useRef
} = React;
const {
  RETAILER,
  PRIVATE
} = svs.components.marketplaceData.constants.groupTypeNames;
const useHasReachedMaxFractionsPerMember = _ref => {
  let {
    group,
    maxFractions,
    maxFractionsPerMember,
    currentUserNumberOfShares
  } = _ref;
  if (!group.type) {
    return undefined;
  }
  let calcHasReachedMaxFractionsPerMember;
  switch (group.type) {
    case RETAILER:
      calcHasReachedMaxFractionsPerMember = maxFractions - currentUserNumberOfShares < 1;
      break;
    case PRIVATE:
      calcHasReachedMaxFractionsPerMember = maxFractionsPerMember - currentUserNumberOfShares < 1;
      break;
    default:
      throw new Error('unknown group type');
  }
  return calcHasReachedMaxFractionsPerMember;
};
const useCantBuy = _ref2 => {
  let {
    group,
    fractionPaymentInProgress,
    game,
    currentUserNumberOfShares
  } = _ref2;
  const {
    maxFractionsPerMember,
    maxFractions,
    buyStop,
    isSubmitted,
    isSoldOut
  } = game;
  const getDiffDateFromNow = date => date.getTime() - Date.now();
  const [isBeforeBuyStop, setIsBeforeBuyStop] = useState(buyStop && getDiffDateFromNow(buyStop) > 0);
  const [buyStopState, setBuystopState] = useState(buyStop);
  const hasReachedMaxFractionsPerMember = useHasReachedMaxFractionsPerMember({
    group,
    maxFractions,
    maxFractionsPerMember,
    currentUserNumberOfShares
  });
  const timeoutToBuyStop = useRef();
  useEffect(() => {
    if (buyStop !== buyStopState && buyStop > new Date()) {
      setIsBeforeBuyStop(true);
      setBuystopState(buyStop);
    }
  }, [buyStop, buyStopState]);
  useEffect(() => {
    if (buyStop && getDiffDateFromNow(buyStop) > 0) {
      if (!timeoutToBuyStop.current) {
        timeoutToBuyStop.current = setTimeout(() => setIsBeforeBuyStop(false), getDiffDateFromNow(buyStop));
      }
    }
    return () => {
      if (timeoutToBuyStop.current) {
        clearTimeout(timeoutToBuyStop.current);
      }
    };
  }, [buyStop]);
  return hasReachedMaxFractionsPerMember || isSoldOut || !isBeforeBuyStop || fractionPaymentInProgress || Boolean(isSubmitted);
};

setGlobal('svs.components.marketplace.fractionPayment', {
  useCantBuy,
  useHasReachedMaxFractionsPerMember
});

 })(window);