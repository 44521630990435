(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/fraction-payment/assets/javascripts/pay-button.js') >= 0) return;  svs.modules.push('/components/marketplace/fraction-payment/assets/javascripts/pay-button.js');
"use strict";


const {
  Button
} = svs.ui;
const {
  format
} = svs.utils;
const {
  WrapLoginLink
} = svs.components.lbUtils.wrapLoginLink;
const {
  breakpoints,
  useMediaQuery,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const {
  useSelector
} = ReactRedux;

const PayButton = _ref => {
  let {
    fractionPrice,
    fractionPurchaseLoading,
    isSmall,
    block,
    onClick,
    cantBuy,
    cantBuyReason
  } = _ref;
  const price = format.Currency(fractionPrice);
  const isCurrentUserAuthenticated = Boolean(useSelector(state => state.customerId));
  const isSmallDevice = useMediaQuery(breakpoints.down(BREAKPOINT_MD));
  const getBuyButtonText = (cantBuy, cantBuyReason, fractionPurchaseLoading, price) => {
    if (cantBuy && cantBuyReason) {
      return cantBuyReason;
    }
    if (fractionPurchaseLoading) {
      return 'Betalar..';
    }
    return "K\xF6p en andel (".concat(price, " kr)");
  };
  return React.createElement(WrapLoginLink, {
    isCurrentUserAuthenticated: isCurrentUserAuthenticated
  }, React.createElement(Button, {
    block: block || !isSmall && isSmallDevice,
    className: "pay-fraction-button qa-pay-fraction-button",
    "data-testid": "qa-pay-fraction-button",
    disabled: cantBuy,
    focus: true,
    onClick: event => {
      event === null || event === void 0 || event.preventDefault();
      onClick(event);
    },
    size: isSmall ? 100 : 300,
    transparent: true
  }, getBuyButtonText(cantBuy, cantBuyReason, fractionPurchaseLoading, price)));
};
setGlobal('svs.components.marketplace.fractionPayment.PayButton', PayButton);

 })(window);