(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/fraction-payment/assets/javascripts/fraction-payment.js') >= 0) return;  svs.modules.push('/components/marketplace/fraction-payment/assets/javascripts/fraction-payment.js');
"use strict";


const {
  BottomSheetIntersersect
} = svs.components.lbUi.bottomSheetIntersect;
const {
  selectors
} = svs.components.marketplaceData;
const {
  useLocation
} = ReactRouterDOM;
const {
  breakpoints,
  useMediaQuery,
  BREAKPOINT_AM
} = svs.components.lbUtils.useMediaQuery;
const {
  PayButton,
  useTriggerPayment,
  useCantBuy,
  useHasReachedMaxFractionsPerMember
} = svs.components.marketplace.fractionPayment;
const {
  useCallback,
  useEffect
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  games
} = svs.components.marketplaceData;
const {
  PRIVATE,
  RETAILER
} = svs.components.marketplaceData.constants.groupTypeNames;
const {
  payFraction
} = svs.components.marketplaceData.fractionPayment.actions;
const {
  reducerStates
} = svs.components.marketplaceData.constants;
const {
  selectPurchaseState
} = svs.components.marketplaceData.selectors.fractionPayment;

const FractionPayment = _ref => {
  let {
    activatedDrawId,
    block,
    className,
    containerId,
    game,
    groupId,
    hasSnackbar,
    isSmall,
    isBrowseGames
  } = _ref;
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUserId = useSelector(state => state.customerId);
  const isCurrentUserAuthenticated = Boolean(currentUserId);
  const group = useSelector(state => selectors.teams.selectTeam(state, {
    id: groupId
  }));
  const isSmallDevice = useMediaQuery(breakpoints.down(BREAKPOINT_AM));
  const {
    currentUserNumberOfShares,
    fractionPrice,
    maxFractionsPerMember,
    maxFractions,
    isSoldOut,
    productId
  } = game;
  const {
    triggerPayment,
    clearPaymentTrigger
  } = useTriggerPayment(activatedDrawId, groupId, isCurrentUserAuthenticated);
  const fractionPaymentInProgress = useSelector(state => selectPurchaseState(state)) === reducerStates.LOADING;
  const cantBuy = useCantBuy({
    group,
    fractionPaymentInProgress,
    game,
    currentUserNumberOfShares
  });
  const hasReachedMaxFractionsPerMember = useHasReachedMaxFractionsPerMember({
    group,
    maxFractions,
    maxFractionsPerMember,
    currentUserNumberOfShares
  });
  const triggerPaymentFlow = useCallback(async () => {
    if (fractionPaymentInProgress) {
      return;
    }
    const userContextCallback = () => {
      if (group.type === RETAILER || isBrowseGames) {
        dispatch(games.actions.fetchActivatedDrawAction(groupId, activatedDrawId, containerId));
      } else if (group.type === PRIVATE) {
        dispatch(games.actions.fetchGameAction(groupId, containerId));
      }
    };
    dispatch(payFraction({
      userContextCallback,
      fractionData: {
        activatedDrawId,
        containerId,
        groupId,
        fractionPrice,
        productId
      }
    }));
  }, [activatedDrawId, containerId, currentUserId, dispatch, fractionPrice, group.type, groupId, isBrowseGames, location.pathname, productId]);
  useEffect(() => {
    if (triggerPayment) {
      clearPaymentTrigger();
      triggerPaymentFlow();
    }
  }, []);
  if (!hasSnackbar) {
    return React.createElement("div", {
      className: className
    }, React.createElement(PayButton, {
      block: block,
      cantBuy: cantBuy,
      fractionPrice: fractionPrice,
      fractionPurchaseLoading: fractionPaymentInProgress,
      isSmall: isSmall,
      onClick: triggerPaymentFlow
    }));
  }
  return React.createElement("div", {
    className: className
  }, isSmallDevice && !hasReachedMaxFractionsPerMember && !isSoldOut && React.createElement("div", {
    className: "snackbar-container"
  }, React.createElement(BottomSheetIntersersect, {
    intersectId: "snackbar-selector"
  }, React.createElement("div", {
    className: "margin-top-1 margin-bottom-1 margin-right margin-left"
  }, React.createElement(PayButton, {
    block: block,
    cantBuy: cantBuy,
    fractionPrice: fractionPrice,
    fractionPurchaseLoading: fractionPaymentInProgress,
    isSmall: isSmall,
    onClick: triggerPaymentFlow
  })))), React.createElement("div", {
    className: className,
    "data-testid": "qa-fraction-snackbar",
    id: "snackbar-selector"
  }, React.createElement(PayButton, {
    block: block,
    cantBuy: cantBuy,
    fractionPrice: fractionPrice,
    fractionPurchaseLoading: fractionPaymentInProgress,
    isSmall: isSmall,
    onClick: triggerPaymentFlow
  })));
};
FractionPayment.defaultProps = {
  block: true,
  className: '',
  hasSnackbar: false,
  isSmall: false
};

setGlobal('svs.components.marketplace.fractionPayment.FractionPayment', FractionPayment);

 })(window);